import axios from "axios";
import { Message } from "element-ui";
import { Loading } from "element-ui";
import router from "../router";

import { clearLoginInfo } from "./index";

import store from "@/store";

let loadingInstance = "";

const http = axios.create({
  baseURL: window.SITE_CONFIG["clientApiURL"],
  // timeout: 3 * 60 * 1000,
  timeout: 30 * 1000,
});

// 请求拦截
http.interceptors.request.use(
  (config) => {

    // 设置保存位置路径的接口，调整请求超时时间
    if (config.url == "/config/checkfilebox" || config.url == "/uploadfile/checkfile" || config.url == "/export/alysestxt" || config.url == "/export/wordsexcel"
      || config.url == "/anchorinfo/stopdecector" || config.url == "/anchorinfo/decector" || config.url == "/form/close"
    ) {
      config.timeout = 3 * 60 * 1000;
    }

    // 设置重新选择行业分析接口的超时时间
    if (config.url == "/anchorvideo/reanalysisbytrade") {
      config.timeout = 3 * 60 * 1000;
    }

    // if (!loadingInstance) {
    //   // 除了主播列表和视频列表接口，都出现lodding
    //   if (config.url != "/anchorinfo/getpageanchor" && config.url != "/anchorvideo/getpage" && config.url != "/config/getuserinfo") {

    //     if (config.url == "/anchorinfo/stopdecector") {
    //       loadingInstance = Loading.service({
    //         lock: true,
    //         background: "rgba(38,50,56,.7)",
    //         text: "正在停止录制和分析"
    //       });
    //     } else {
    //       loadingInstance = Loading.service({
    //         lock: true,
    //         background: "rgba(38,50,56,.7)",
    //       });
    //     }

    //     store.commit("saveLoading", loadingInstance);
    //     store.commit("saveLoadingRerouce", "client");
    //   }
    // }

    if (config.url != "/anchorinfo/getpageanchor" && config.url != "/anchorvideo/getpage" && config.url != "/config/getuserinfo") {

      if (config.url == "/anchorinfo/stopdecector") {
        loadingInstance = Loading.service({
          lock: true,
          background: "rgba(38,50,56,.7)",
          text: "正在停止录制和分析"
        });
      } else {
        loadingInstance = Loading.service({
          lock: true,
          background: "rgba(38,50,56,.7)",
        });
      }

    }


    config.headers.Token = store.state.token || "";

    return config;
  },
  (error) => {
    if (loadingInstance) {
      loadingInstance.close();
    }

    return Promise.reject(error);
  }
);

// 响应拦截
http.interceptors.response.use(
  (res) => {
    if (loadingInstance) {
      loadingInstance.close();
    }

    if (res.status == 200 && res.data.code === 0) {
      return res.data;
    } else {
      let resErr_text = "";
      if (res.status != 200) {
        resErr_text = "与服务器网络连接断开，请稍后重试或联系管理员";
      } else {
        resErr_text = res.data.msg || "请求返回信息错误";
      }

      if (res.data.code === 4001) {
        clearLoginInfo();
        router.replace({ name: "login" });
      }
      Message.error(resErr_text);
      return Promise.reject(res.data);
    }
  },
  (error) => {
    // 主播列表，直接跳过，不弹出错误信息
    if (error.config.url == "/anchorinfo/getpageanchor") {
      return Promise.reject(error);
    }
    if (loadingInstance) {
      loadingInstance.close();
    }
    let err_txt = "";
    if (error.message.indexOf("timeout of") != -1) {
      err_txt = "网络较差,请再试一次";
    } else if (error.message.indexOf("Network Error") != -1) {
      err_txt = "网络较差,请再试一次";
    }
    Message.error(err_txt);
    return Promise.reject(error);
  }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */

export const get = (url, params) => {

  return http({
    url,
    params,
  });
};

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} data [请求时携带的参数]
 */
export const post = (url, data) => {
  return http({
    url,
    data,
    method: "post",
  });
};
