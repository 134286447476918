import { get, post } from "./request-back";

const api = {};

// 用户
api.user = {
  register: (data) => post("/user/register", data),
  getPhoneCode: (data) => get("/user/getPhoneCode", data),
  login: (data) => post("/user/login", data),
  logout: (data) => get("/user/logout", data),
  updateByClient: (data) => post("/user/updateByClient", data),
  infoByClient: (data) => get("/user/infoByClient", data),
  checkPhoneCode: (data) => get("/user/checkPhoneCode", data),
  checkFreeze: (data) => get("/user/checkFreeze", data),
};

api.file = {
  showOne: (data) => get("/file/showOne", data),
}

api.trade = {
  listTree: (data) => get("/trade/listTree", data),
  list: (data) => post("/trade/list", data),
}

api.dictdata = {
  list: (data) => post("/dictdata/list", data),
}

api.article = {
  list: (data) => post("/article/list", data),
}

// 词库
api.lexicon = {
  list: (data) => post("/lexicon/list", data),
  info: (data) => get("/lexicon/info", data),
  save: (data) => post("/lexicon/save", data),
  update: (data) => post("/lexicon/update", data),
  delete: (data) => get("/lexicon/delete", data),
};

api.uploadUrl = window.SITE_CONFIG['backApiURL'] + "/common/uploadImg"; // 头像上传地址

export default api;
