import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedstate from 'vuex-persistedstate'

Vue.use(Vuex)

const PERSIST_PATHS = ['userInfo', 'token', 'replayPageMenu', 'homeMenu', 'detectionStatus', 'startDetectionTime']
export default new Vuex.Store({
  plugins: [createPersistedstate({
    key: 'replayVuex',
    paths: PERSIST_PATHS
  })],
  state: {
    userInfo: '',
    replayPageMenu: {},
    token: '',
    homeMenu: "",
    detectionStatus: "",
    startDetectionTime: ""
  },
  mutations: {
    // 保存登录返回的信息
    saveLoginResultData(state, val) {
      state.token = val ? (val.token || '') : ''
      state.userInfo = val || ''
    },
    // 保存用户信息
    saveUserInfo(state, val) {
      state.userInfo = val || ''
    },
    // 设置是否加载了动态路由标识
    setLoadRouterFlag(state, val) {
      state.loadRouterFlag = val;
    },
    // 保存复盘页面菜单标识
    saveReplayPageMenu(state, val) {
      state.replayPageMenu = val;
    },
    // 保存首页菜单索引
    saveHomeMenu(state, val) {
      state.homeMenu = val;
    },
    // 保存设置页菜单索引
    saveSetupMenu(state, val) {
      state.setupMenu = val;
    },
    // 保存检测状态
    saveDetectionStatus(state, val) {
      state.detectionStatus = val;
    },
    // 保存检测开始时间
    saveDetectionTime(state, val) {
      state.startDetectionTime = val;
    },
    // 保存分析的视频id
    saveVideoAnalysisId(state, val) {
      state.videoAnalysisId = val;
    },
    // 保存分析的文件id
    saveFileAnalysisId(state, val) {
      state.fileAnalysisId = val;
    },
    // 保存进入主播列表页是否停止录制标识
    saveStopRecord(state, val) {
      state.stopRecord = val;
    },
    // 保存选择分析的行业数组
    saveAnalysisTrade(state, val) {
      state.analysisTrade = val;
    },
    saveLoading(state, val) {
      state.loadingInstance = val;
    },
    saveLoadingRerouce(state, val) {
      state.loadingRerouce = val;
    }
  },
  actions: {
  },
  modules: {
  }
})
