import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { Message } from "element-ui";

// 页面路由
const pageRoutes = [
  { path: '/login', name: 'login', component: () => import('@/views/pages/login'), meta: { title: '登录' } },
  { path: '/', name: 'login', component: () => import('@/views/pages/login'), meta: { title: '登录' } }
]

// 模块路由
// const moduleRoute = {
//   path: '/',
//   redirect: { name: 'login' },
//   name: 'main',
//   component: () => import('@/views/main'),
//   meta: {
//     title: '主入口'
//   }
// }

Vue.use(VueRouter)

// const router = new VueRouter({
//   routes: pageRoutes.concat(moduleRoute)
// })
const router = new VueRouter({
  routes: pageRoutes
})

// 路由跳转前的处理方法
router.beforeEach((to, from, next) => {
  // 获取加载路由标识
  let loadFlag = store.state.loadRouterFlag;
  // 当前是登录路由，或已添加动态菜单路由，跳过
  if (to.name == 'login' || loadFlag) {
    return next();
  }

  // 拿到json菜单数据
  let menuList = require('@/assets/json/menu.json');
  // 将菜单添加到路由
  fnAddDynamicMenuRoutes(menuList);
  // 表示已添加过路由
  store.commit("setLoadRouterFlag", true);

  next({ ...to, replace: true })

})

var fnAddDynamicMenuRoutes = (menuList) => {

  menuList.forEach(ele => {
    router.addRoute('main', {
      name: ele.name,
      path: ele.path,
      component: () => import('@/views/modules' + ele.path)
    })
  });

  // 已添加动态菜单路由，修改状态
  store.commit("setLoadRouterFlag", true);

}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
export default router
