import axios from "axios";
import { Message } from "element-ui";
import { Loading } from "element-ui";
import router from "../router";

import { clearLoginInfo } from "./index";

import store from "@/store";

let loadingInstance = "";

const http = axios.create({
  baseURL: window.SITE_CONFIG["backApiURL"],
  timeout: 1500 * 10,
});

// 请求拦截
http.interceptors.request.use(
  (config) => {

    // if (!store.state.loadingInstance) {
    //   loadingInstance = Loading.service({
    //     lock: true,
    //     background: "rgba(38,50,56,.7)",
    //   });

    //   store.commit("saveLoading", loadingInstance);
    //   store.commit("saveLoadingRerouce", "back");
    // }

    loadingInstance = Loading.service({
      lock: true,
      background: "rgba(38,50,56,.7)",
    });

    config.headers.Token = store.state.token || "";

    return config;
  },
  (error) => {
    if (loadingInstance) {
      loadingInstance.close();
    }
    return Promise.reject(error);
  }
);

// 响应拦截
http.interceptors.response.use(
  (res) => {
    if (loadingInstance) {
      loadingInstance.close();
    }

    if (res.status == 200 && res.data.code === 0) {
      return res.data;
    } else {
      let resErr_text = "";
      if (res.status != 200) {
        resErr_text = "与服务器网络连接断开，请稍后重试或联系管理员";
      } else {
        resErr_text = res.data.msg || "请求返回信息错误";
      }

      if (res.data.code === 4001) {
        clearLoginInfo();

        router.replace({ name: "login" });
      }

      Message.error(resErr_text);

      return Promise.reject(res.data);
    }
  },
  (error) => {
    if (loadingInstance) {
      loadingInstance.close();
    }
    let err_txt = "";
    if (error.message.indexOf("timeout of") != -1) {
      err_txt = "网络较差,请再试一次";
    } else if (error.message.indexOf("Network Error") != -1) {
      err_txt = "网络较差,请再试一次";
    }
    Message.error(err_txt);
    return Promise.reject(error);
  }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */

export const get = (url, params) => {

  return http({
    url,
    params,
  });
};

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} data [请求时携带的参数]
 */
export const post = (url, data) => {
  return http({
    url,
    data,
    method: "post",
  });
};
