import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import httpBack from './utils/request-api-back'
import httpClient from './utils/request-api-client'
import { myMixin } from '@/mixins/mixins.js'
import VueVideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css';
import 'video.js/dist/video-js.css'
import 'video.js/dist/lang/zh-CN.js'

import '@/icons'
import '@/assets/scss/bh.scss'

import echarts from '@/echarts/index.js'

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

Vue.prototype.$echarts = echarts

Vue.use(ElementUI)

Vue.use(VueVideoPlayer)

Vue.prototype.$httpBack = httpBack
Vue.prototype.$httpClient = httpClient

Vue.config.productionTip = false

Vue.mixin(myMixin)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
