import { get, post } from "./request-client";

const api = {};

// 窗体
api.form = {
  togglemaxsize: (data) => get("/form/togglemaxsize", data), // 最大化/恢复
  minsize: (data) => get("/form/minsize", data), // 最小化
  close: (data) => get("/form/close", data), // 关闭
};

// 系统设置
api.setup = {
  getmodel: (data) => get("/config/getmodel", data), // 获取基本设置信息
  updatemodel: (data) => post("/config/updatemodel", data), // 修改基本设置
  checkfilebox: (data) => get("/config/checkfilebox", data), // 打开文件选择器
  closedialog: (data) => get("/config/closedialog", data), // 关闭文件选择器
  settoken: (data) => get("/config/settoken", data), // 更新token
  getdisksize: (data) => get("/config/getdisksize", data), // 读盘空间
  getmaxdisk: (data) => get("/config/getmaxdisk", data), // 返回剩余空间容量最大的盘符
}

// 主播
api.compere = {
  saveanchorinfo: (data) => post("/anchorinfo/saveanchorinfo", data), // 添加主播
  getpageanchor: (data) => get("/anchorinfo/getpageanchor", data), // 主播列表
  openfolder: (data) => get("/anchorinfo/openfolder", data), // 打开目录
  openorcloseautorecord: (data) => get("/anchorinfo/openorcloseautorecord", data), // 开启、关闭自动录制
  stoprecord: (data) => get("/anchorinfo/stoprecord", data), // 停止单个主播录制
  startRecord: (data) => get("/anchorinfo/StartRecord", data), // 开启、关闭自动录制
  decector: (data) => get("/anchorinfo/decector", data), // 开始检测
  stopdecector: (data) => get("/anchorinfo/stopdecector", data), // 停止检测
  previewvideo: (data) => get("/anchorinfo/previewvideo", data), // 预览视频
  removeanchor: (data) => get("/anchorinfo/removeanchor", data), // 删除主播
  updateanchortrade: (data) => get("/anchorinfo/updateanchortrade", data), // 修改主播行业
}

// 视频
api.video = {
  getpage: (data) => get("/anchorvideo/getpage", data), // 视频列表
  createAnalysis: (data) => get("/anchorvideo/createanalysis", data), // 生成视频分析
  lockanalysis: (data) => get("/anchorvideo/lockanalysis", data), // 查看视频分析
  savecontrast: (data) => post("/anchorvideo/savecontrast", data), // 创建视频分析对比
  getcontrastpage: (data) => get("/anchorvideo/getcontrastpage", data), // 分页获取对比列表
  lockanalysiscontrast: (data) => get("/anchorvideo/lockanalysiscontrast", data), // 查看视频对比分析
  preview: (data) => get("/anchorvideo/preview", data), // 预览
  openFolder: (data) => get("/anchorvideo/openfolder", data), // 打开视频目录
  reAnalysisByTrade: (data) => get("/anchorvideo/reanalysisbytrade", data), // 重新选择行业分析
  reanalysis: (data) => get("/anchorvideo/reanalysis", data), // 重新分析
  deletebyids: (data) => post("/anchorvideo/deletebyids", data), // 根据视频id集合删除视频
  stopAutoAnalysis: (data) => post("/anchorvideo/stopAutoAnalysis", data), // 停止自动分析
  shareAnalysis: (data) => get("/anchorvideo/shareanalysis", data), // 分享复盘
  confirmUseMark: (data) => get("/anchorvideo/confirmusemark", data), // 确认消耗标注资源
}

// 文件上传 
api.uploadFile = {
  checkfile: (data) => get("/uploadfile/checkfile", data), // 选择文件
  getpage: (data) => get("/uploadfile/getpage", data), // 文件列表
  lockanalysis: (data) => get("/uploadfile/lockanalysis", data), // 查看视频分析
  preview: (data) => get("/uploadfile/preview", data), // 预览
  reAnalysisByTrade: (data) => get("/uploadfile/reanalysisbytrade", data), // 重新选择行业分析
  reanalysis: (data) => get("/uploadfile/reanalysis", data), // 重新分析
  deletebyids: (data) => post("/uploadfile/deletebyids", data), // 根据文件id集合删除文件
  createAnalysis: (data) => get("/uploadfile/createanalysis", data), // 重新分析
  shareAnalysis: (data) => get("/uploadfile/shareanalysis", data), // 分享复盘
  confirmUseMark: (data) => get("/uploadfile/confirmusemark", data), // 确认消耗标注资源
}

// 导出 
api.export = {
  alysestxt: (data) => get("/export/alysestxt", data), // 导出视频/文件文字内容
  wordsexcel: (data) => get("/export/wordsexcel", data), // 导出敏感词/关键词列表excel
}

api.syncData = {
  syncData: (data) => get("/sync/syncdata", data), // 同步数据
}

// 用户资产 
api.userProperty = {
  info: (data) => get("/userproperty/info", data), // 获取用户资产
}

// 用户信息 
api.user = {
  info: (data) => get("/config/getuserinfo", data), // 获取用户信息
}

export default api;
